<template>
  <div class="settlement bj">
    <div class="title">
      <div class="seach order-wrapper">
        <div class="inputs">
          <el-input
            v-model="from1.supplier_order_no"
            placeholder="请输入供货单编号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.title"
            placeholder="请输入供货商名称"
            clearable
          ></el-input>
        </div>
        <div class="times">
          <el-date-picker
            v-model="value"
            @change="createChange"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="创建开始日期"
            end-placeholder="创建结束日期"
          >
          </el-date-picker>
        </div>
        <div class="times">
          <el-date-picker
            v-model="value1"
            @change="countChange"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="结算开始日期"
            end-placeholder="结算结束日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.liquidation_status"
            clearable
            placeholder="请选择状态"
          >
            <el-option label="全部结算状态" value=""></el-option>
            <el-option label="已结算" value="1"></el-option>
            <el-option label="未结算" value="2"></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出列表</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledDetailsExport"
          @click="onHandleExportDetails"
          type="warning"
          icon="el-icon-download"
          >导出明细</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="supplier_order_no"
          align="center"
          label="供货单编号"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.supplier_order_no) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.logistic_business_name) }}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="供货商名称">
          <template slot-scope="scope" v-if="scope.row.supplier">
            {{ $empty.empty(scope.row.supplier.title) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="product_amount"
          align="center"
          label="供货单金额"
        >
          <template slot-scope="scope">
            {{ $empty.empty(tool.format_money(scope.row.product_amount)) }}
          </template>
        </el-table-column>
        <el-table-column prop="qc_amount" align="center" label="复核缺货金额">
          <template slot-scope="scope">
            {{ scope.row.qc_amount || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="aftersale_amount"
          align="center"
          label="供应商售后扣款金额"
        >
          <template slot-scope="scope">
            {{ scope.row.aftersale_amount || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="liquidation_status"
          align="center"
          label="结算状态"
        >
          <template slot-scope="scope">
            {{
              $empty.empty(
                scope.row.liquidation_status == 1 ? "已结算" : "未结算"
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="liquidation_time"
          align="center"
          label="结算日期"
        >
          <template slot-scope="scope">
            {{ $empty.time(scope.row.liquidation_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="service_fee_amount"
          :label="serviceFeeLabel"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="service_sell_fee"
          label="平台销售服务费"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="liquidation_amount"
          align="center"
          label="结算金额"
        >
          <template slot-scope="scope">
            {{ $empty.empty(tool.format_money(scope.row.liquidation_amount)) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'settleDetail', query: { id: scope.row.id } }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--
  新增中心账号 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="500px"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务模块" prop="business_type_code">
            <div style="width: 302px">
              <el-select
                v-model="ruleForm.business_type_code"
                clearable
                placeholder="选择业务模块"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="业务名称" prop="itemdata">
            <div style="width: 302px">
              <el-select
                v-model="ruleForm.itemdata"
                clearable
                @change="changestore"
                placeholder="选择业务名称"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="JSON.stringify(item)"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <div style="width: 302px">
              <el-input
                v-model="ruleForm.username"
                placeholder="请输入账号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <div style="width: 302px">
              <el-input
                v-model="ruleForm.password"
                placeholder="请输入密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianku">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { BASE } from "@/api";
import url from "url";
// import { postSupplierOrderSettleExport } from "@/api/export/center";

export default {
  name: "supplier",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        supplier_order_no: "",
        title: "",
        create_start: "",
        create_end: "",
        liquidation_start: "",
        liquidation_end: "",
        liquidation_status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      },
      from1: {
        supplier_order_no: "",
        title: "",
        create_start: "",
        create_end: "",
        liquidation_start: "",
        liquidation_end: "",
        liquidation_status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      title: "",
      value: "",
      value1: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      list: [],
      list1: [],
      logisticsList: [], // 集配中心列表
      // 手续费比例文案
      serviceFeeLabel: "",
      disabledExport: false, // 导出禁用（导出列表）
      disabledDetailsExport: false, // 导出禁用（导出明细）
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      let url = this.tool.getUrl(window.location.href);
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      this.value = [this.from1.create_start, this.from1.create_end];
      this.value1 = [this.from1.liquidation_start, this.from1.liquidation_end];
      // 集配中心 回显
      this.from.logistic_business_id = this.from.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";
      this.from1.logistic_business_id = this.from1.logistic_business_id
        ? Number(this.from1.logistic_business_id)
        : "";
      console.log(this.from1);
    }
    this.hqlist();
    this.getAjaxLogisticsList();
    //   this.seletlist();
  },
  methods: {
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    createChange(val) {
      console.log(val);
      if (val) {
        this.from1.create_start = val[0];
        this.from1.create_end = val[1];
      } else {
        this.from1.create_start = "";
        this.from1.create_end = "";
      }
    },
    countChange(val) {
      console.log(val);
      if (val) {
        this.from1.liquidation_start = val[0];
        this.from1.liquidation_end = val[1];
      } else {
        this.from1.liquidation_start = "";
        this.from1.liquidation_end = "";
      }
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.from = {
        supplier_order_no: "",
        title: "",
        create_start: "",
        create_end: "",
        liquidation_start: "",
        liquidation_end: "",
        liquidation_status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.from1 = {
        supplier_order_no: "",
        title: "",
        create_start: "",
        create_end: "",
        liquidation_start: "",
        liquidation_end: "",
        liquidation_status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.value = "";
      this.value1 = "";
      this.currentPage = 1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 旧导出逻辑 */
      // window.open(
      //   BASE.PRO1 +
      //     `/supplier/order/settle/list/export?title=` +
      //     this.from1.title +
      //     "&supplier_order_no=" +
      //     this.from1.supplier_order_no +
      //     "&create_start=" +
      //     this.from1.create_start +
      //     "&create_end=" +
      //     this.from1.create_end +
      //     "&liquidation_start=" +
      //     this.from1.liquidation_start +
      //     "&liquidation_end=" +
      //     this.from1.liquidation_end +
      //     "&liquidation_status=" +
      //     this.from1.liquidation_status +
      //     "&logistic_business_id=" +
      //     this.from1.logistic_business_id +
      //     "&token=" +
      //     sessionStorage.getItem("token"),
      //   "_blank"
      // );
      /** 旧接口，精简逻辑 */
      const query = { ...this.from1, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/supplier/order/settle/list/export";
      window.open(path + params);
      /** 新导出逻辑 */
      // this.disabledExport = true;
      // try {
      //   const query = {
      //     ...this.from1,
      //     token: sessionStorage.getItem("token"),
      //   };
      //   delete query.page;
      //   delete query.pageSize;
      //   await postSupplierOrderSettleExport(query);
      //   this.goExportCenter();
      // } catch (err) {
      //   console.log("ajax postSupplierOrderSettleExport err", err);
      //   this.disabledExport = false;
      // }
    },
    /**
     * 导出明细
     */
    onHandleExportDetails() {
      const query = { ...this.from1, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/supplier/order/settle/detail/export";
      window.open(path + params);
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.general.settleList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        const { service_fee_title } = res.data;
        this.serviceFeeLabel = `金融手续费(${service_fee_title || "0.4%"})`;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.settlement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    // .seach {
    //   flex: 1;
    //   display: flex;
    //   justify-content: flex-end;
    //   .inputs {
    //     width: 207px;
    //     margin-right: 5px;
    //   }
    // }
    .order-wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      background: #ffffff;
      padding: 10px 15px;

      .inputs {
        width: 207px;
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .multiple-select {
        width: 320px;
      }
      .multiple-select-long {
        width: 340px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
  .el-picker-panel {
    left: 0 !important;
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
